import bg1 from "../src/assets/images/bg/removeBgImg.webp";
import bg2 from "../src/assets/images/landingpage/addImageCategories.webp";
import bg3 from "../src/assets/images/landingpage/addedImageBgCategories.webp";
import bg4 from "../src/assets/images/landingpage/removeImageCategories.webp";
import bg5 from "../src/assets/images/landingpage/addedBackground.webp";
import bg6 from "../src/assets/images/landingpage/Apitools.webp";
import cercle from "../src/assets/images/landingpage/cercle.png";
import BackgroundRemover from "../src/components/landingpage/SectionOne";
import Sectiontwo from "../src/components/landingpage/SectionTwo";
import PricingSection from "../src/components/landingpage/PricingSection";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import {
  getallsubscriptions,
  pricinglist,
} from "../src/Redux/Subscription/SubscriptionSlice";
import NewsLetter from "../src/components/dashboard/newsletter";
import Image from "next/image";
import BlogSection from "../src/components/landingpage/BlogSection";
import Head from "next/head";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

const Home = () => {
  const { t } = useTranslation("common");
  const images = [
    { url: bg2, label: t("imageLabels.original") , alt:t("imagealt.original")},
    { url: bg3, label: t("imageLabels.backgroundRemoved") ,alt:t("imagealt.backgroundRemoved")},
    { url: bg4, label: t("imageLabels.newBackground") , alt:t("imagealt.newBackground")},
  ];
  const dispatch = useDispatch();
  

  useEffect(() => {
    dispatch(getallsubscriptions());
  }, [dispatch]);

  const subscriptions = useSelector(pricinglist);
  const sectionRefs = useRef([]);
useEffect(() => {
  if (!sectionRefs.current) {
    sectionRefs.current = []; // Fallback initialization
  }
}, []);
  useEffect(() => {
    const currentSections = sectionRefs.current; // Ensure the current array of refs is used
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };
  
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          observer.unobserve(entry.target);
        }
      });
    }, options);
  
    currentSections.forEach((section) => {
      if (section) observer.observe(section); // Only observe valid elements
    });
  
    return () => {
      currentSections.forEach((section) => {
        if (section) observer.unobserve(section); // Clean up observers
      });
    };
  }, []);

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Pixeloop | AI-Powered Image Editing Tools",
    description:
      "Enhance your images with AI-powered tools like background removal and addition. Explore our pricing plans and get started today.",
    url: process.env.NEXT_PUBLIC_BASE_URL + "/",
    image: BASE_URL + "/images/LandingPage/remove-image-categories.svg",
    author: {
      "@type": "Organization",
      name: "Pixeloop",
      url: BASE_URL,
    },
    publisher: {
      "@type": "Organization",
      name: "Pixeloop",
      logo: {
        "@type": "ImageObject",
        url: BASE_URL + "/images/Logo/pixeloop-logo.svg",
      },
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": BASE_URL + "/",
    },
    potentialAction: {
      "@type": "Action",
      name: "Explore AI Tools",
      target: {
        "@type": "EntryPoint",
        urlTemplate: BASE_URL + "/tools",
      },
    },
    offers: {
      "@type": "Offer",
      name: "Pricing Plans",
      description:
        "Explore affordable pricing plans for all our AI-powered image editing tools.",
      url: BASE_URL + "/tarifs",
      priceCurrency: "TND",
      offers: [
        {
          "@type": "Offer",
          price: "0.00",
          priceCurrency: "TND",
          description: "Free Plan - Try our ai model.",
        },
        {
          "@type": "Offer",
          price: "39.99",
          priceCurrency: "TND",
          description: "Basic Plan - Ideal for small image editing needs.",
        },
        {
          "@type": "Offer",
          price: "69.99",
          priceCurrency: "TND",
          description:
            "Gold Plan - Best for professionals with higher editing requirements.",
        },
        {
          "@type": "Offer",
          priceCurrency: "TND",
          description:
            "Enterprise Plan - Perfect for large teams and organizations.",
        },
      ],
    },
  };

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      </Head>
      <div className="homepage-background">
        <div className="homepage-background-content"></div>
      </div>
      <div className="indexpage">
        <div ref={(el) => (sectionRefs.current[0] = el)} className="section">
          <BackgroundRemover
            title={
              <div className="inline-title">
                <span>
                  {t("hero.title")}{" "}
                  <span className="text-style">
                    with AI
                    <span className="responsive-circle">
                      <Image src={cercle} alt={t("imagealt.circle")} layout="intrinsic" />
                    </span>
                  </span>
                </span>
              </div>
            }
            description={t("hero.description")}
            buttontext={t("hero.button")}
            imageUrl={bg1}
            imgPosition="right"
            titleColor="#000000"
            buttonColor="primary"
            buttonBgColor="transparent"
            btnUrl="/remove-background"
            alt={t("imagealt.bgremover")}
            imgwidth={577}
            imgheight={379}
          />
        </div>
        <div className="home-container">
          <div ref={(el) => (sectionRefs.current[1] = el)} className="section">
            <BackgroundRemover
              title={t("addBackground.title")}
              description={t("addBackground.description")}
              buttontext={t("addBackground.button")}
              imageUrl={bg5}
              imgPosition="left"
              titleColor="#000000"
              buttonColor="primary"
              buttonBgColor="transparent"
              btnUrl="/add-background"
              alt={t("imagealt.bgadder")}
              imgwidth={536}
              imgheight={403}
            />
          </div>
          <div ref={(el) => (sectionRefs.current[2] = el)} className="section">
            <Sectiontwo
              title={
                <>
                  {" "}
                  <span className="inline-text">
                    {" "}
                    {t("imagine.just")}{" "}
                  </span>{" "}
                  <span className="text-style">
                    {t("imagine.title")}
                    <span className="responsive-circle responsive-circle-100">
                      <Image src={cercle} alt={t("imagealt.circle")} layout="intrinsic" />
                    </span>
                  </span>
                </>
              }
              images={images}
              description={t("imagine.description")}
            />
          </div>
          <div ref={(el) => (sectionRefs.current[3] = el)} className="section">
            <BackgroundRemover
              title={t("integration.title")}
              description={t("integration.description")}
              buttontext={t("integration.button")}
              imageUrl={bg6}
              imgPosition="right"
              titleColor="#00000"
              buttonColor="primary"
              buttonBgColor="transparent"
              btnUrl="/remove-background"
              alt={t("imagealt.api&tools")}
              imgwidth={536}
              imgheight={403}
            />
          </div>
          <div ref={(el) => (sectionRefs.current[4] = el)} className="section">
            <PricingSection
              title={
                <>
                  {" "}
                  <span>
                    {t("pricing.title")}
                    <span className="text-style">
                      {t("pricing.subtitle")}
                      <span className="responsive-circle my-3">
                        <Image src={cercle} alt={t("imagealt.circle")}layout="intrinsic" />
                      </span>
                    </span>
                  </span>
                </>
              }
              description={t("pricing.description")}
              pricingPlans={subscriptions}
            />
          </div>

          <div ref={(el) => (sectionRefs.current[5] = el)} className="section">
            <BlogSection
              title={
                <>
                  {" "}
                  <span className="inline-text">{t("blog.title")}</span>
                  <span className="text-style">
                    {t("blog.subtitle")}
                    <span className="responsive-circle responsive-circle-50 ">
                      <Image src={cercle} alt={t("imagealt.circle")} layout="intrinsic" />
                    </span>
                  </span>
                </>
              }
              images={images}
              description={t("blog.description")}
            />
          </div>
        </div>

        <div
          ref={(el) => (sectionRefs.current[6] = el)}
          className="section d-flex justify-content-center newslater-blog"
        >
          <NewsLetter />
        </div>
      </div>
    </>
  );
};
const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}

export default Home;
