import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RateImage } from "./ImageAction";

const initialState = {
  success:'error'
};


export const addImageRating = createAsyncThunk(
  "RATE IMAGE",
  async(formData)=>{
    const response=await RateImage(formData);
    
    return response
  }
)

export const RateImageSlice = createSlice({
  name: "images",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
 

    builder.addCase(addImageRating.fulfilled , (state,action)=>{
      state.success='success'
    })
    builder.addCase(addImageRating.rejected, (state, action) => {
        state.success = 'error';
        console.error(action.payload); // Gérer les erreurs si nécessaire
      });
  },
});

export default RateImageSlice.reducer;
