import { axiosInstance } from "../../config/Axios";
const API_URL = process.env.NEXT_PUBLIC_API_URL;


export const RATE_IMAGE_PATH = API_URL + "/rate_image";

export function RateImage(formData) {
    return axiosInstance
      .post(RATE_IMAGE_PATH, formData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }