import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import SubscriptionReducer from "../Redux/Subscription/SubscriptionSlice";
import UploadImageReducer from "../Redux/uploadFile/UploadFileSLice";
import UserReducer from "../Redux/user/UserSlice";
import BlogReducer from "../Redux/Blog/BlogSlice";
import AlertReducer from "../Redux/alert/alertSlice";
import ModalReducer from "../Redux/alert/modalLogoutSlice"
import BannerReducer from "../Redux/alert/BannerSlice"
import recaptchaReducer from "../Redux/recapcha/recaptchaSlice"
import ImageReducer from "../Redux/image/ImageSlice"

const reducers = combineReducers({
  subscriptions: SubscriptionReducer,
  uploadImage: UploadImageReducer,
  user: UserReducer,
  blog: BlogReducer,
  alert:AlertReducer,
  banner:BannerReducer,
  logoutModal:ModalReducer,
  recaptcha: recaptchaReducer,
  image:ImageReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "develop",
  middleware: [thunk],
});

export const persistor = persistStore(store);
