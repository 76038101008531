import bg1 from "../../src/assets/images/icons/Vector.png";
import Image from "next/image";
import { useDispatch } from "react-redux";
import { addUserSubscription } from "../Redux/Subscription/SubscriptionSlice";
import { showAlert } from "../Redux/alert/alertSlice";
import { hideAlert } from "../Redux/alert/BannerSlice";
import { useTranslation } from "next-i18next";

const PriceCard = ({
  accountType,
  price,
  features,
  buttonLabel,
  icon,
  quantity,
  id,
}) => {
  const { t } = useTranslation();
  const included = true;
  const dispatch = useDispatch();
  const handleSubscriptionClick = async () => {
    if (accountType.toUpperCase()==='ENTREPRISE'){
      window.location.href = "/contact-us";
      return;
    }
    const subscriptionData = {
      subscription_type_id: id,
    };

    try {
      const response = await dispatch(
        addUserSubscription(subscriptionData)
      ).unwrap();
   
      if (response && response.payUrl) {

        window.location.href = response.payUrl;
      } else if (response.code === "2000") {
        // alert("You already have an active subscription.");
        dispatch(
          showAlert({
            message:t("pricing.alerts.succesufulysubscription"),
            color: "success",
          })
        );
        dispatch(hideAlert());
      }
      else if (response.code === "4004") {
        // alert("You already have an active subscription.");
        dispatch(
          showAlert({
            message:  t("pricing.alerts.loginRequired"),
            color: "danger",
          })
        );
      }
      else {
        dispatch(
          showAlert({
            message:  t("pricing.alerts.loginRequired"),
            color: "danger",
          })
        );
      }
    } catch (error) {
     
        dispatch(
          showAlert({
            message:  t("pricing.alerts.serverError"),
            color: "danger",
          })
        );
      }
    
  };

  return (
    <div className="price-card">
      {/* <Image src={icon?.Premiumicon?.src} alt={`${accountType} icon`} width={30} height={30} /> */}
      <span className="icon-price-card">
        <Image 
        src={icon}
        alt={`${accountType} icon`}
        />
      </span>
      <h3 className="account-type">{accountType.toUpperCase()} </h3>
      <h3 className="price">{price}{accountType!=='Entreprise' && 'TND'}</h3>
      <p className="per-month"> {quantity} {t('pricing.plans.features.credits')}</p>
      <ul className="features">
        {features.map((feature, index) => (
          <li key={index} className={included ? "included" : "not-included"}>
            <div>
              <Image src={bg1} alt="Feature-icon" width={15} height={15} />{" "}
              {feature.name}
            </div>
          </li>
        ))}
      </ul>
      <button className="btn buy-button" onClick={handleSubscriptionClick} disabled={accountType.toUpperCase()=="FREE"}>
        {buttonLabel}
      </button>
    </div>
  );
};

export default PriceCard;

