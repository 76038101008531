import FullLayout from "../src/layouts/FullLayout";
import "../styles/style.scss";
import { NextSeo } from "next-seo";
import { Provider } from "react-redux";
import { store, } from "../src/config/Store";
import LoginLayout from "../src/layouts/LoginLayout";
import loginImg from "../src/assets/images/background/signinImg@.svg";
import registerImg from "../src/assets/images/background/signupImg@.svg";
import forgotpasswordImg from "../src/assets/images/background/forgotpassword.png";
import { useRouter } from "next/router";
import { isAuthenticated } from "../src/helpers/auth";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { appWithTranslation, useTranslation } from "next-i18next";
import SEO from "../src/components/SEO";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { t } = useTranslation();

  useEffect(() => {
    const privateRoutes = [
      "/profile",
      "/account-details",
      "/subscription-details",
      "/subscription-history",
      "/change-password",
    ];
    const publicRoutes = ["/login", "/register", "forgot-password"];
    const profileRoutes = [
      "/account-details",
      "/subscription-details",
      "/subscription-history",
      "/change-password",
    ];

    if (profileRoutes.includes(router.pathname) && !isMobile) {
      router.push("/profile");
    }
    if (privateRoutes.includes(router.pathname) && !isAuthenticated()) {
      router.push("/login");
    }
    if (publicRoutes.includes(router.pathname) && isAuthenticated()) {
      router.push("/");
    }
  }, [isMobile, router]);

  const getLayout = (Component) => {
    if (
      Component.layout === "login" ||
      Component.layout === "register" ||
      Component.layout === "forget-password"
    ) {
      return LoginLayout;
    }
    return FullLayout;
  };
  const Layout = getLayout(Component);
  const imgSrc =
    Component.layout === "login"
      ? loginImg
      : Component.layout === "register"
      ? registerImg
      : Component.layout === "forget-password"
      ? forgotpasswordImg
      : forgotpasswordImg;

      const componentName = Component.displayName || 'defaultPage';
      const translatedSeo = {
        title: t(`${componentName}.title`),
        description: t(`${componentName}.description`),
        additionalMetaTags: [
          { name: 'keywords', content: t(`${componentName}.keywords`) },
          { name: 'robots', content: t(`${componentName}.robots`) },
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:title', content: t(`${componentName}.twitter.title`) },
          { name: 'twitter:description', content: t(`${componentName}.twitter.description`) },
          { name: 'twitter:image', content: Component?.seo?.additionalMetaTags.find(meta => meta.name === "twitter:image").content },
          { name: 'canonical', content: Component?.seo?.additionalMetaTags.find(meta => meta.name === "canonical").content },
        ],
        openGraph: {
          type: 'website',
          description: t(`${componentName}.openGraph.description`),
          url: Component?.seo?.openGraph?.url,
          title: t(`${componentName}.openGraph.title`),
          images: Component?.seo?.openGraph?.images.map(image => image.url)
        }
      };
  return (
    <>
      {/* Rendre le SEO personnalisé avant PersistGate */}
      {Component.seo ? <NextSeo {...translatedSeo} /> : <SEO />}
      {/* Google Analytics */}
      <GoogleAnalytics trackPageViews={{ ignoreHashChange: true }} />

      {/* Provider Redux avec PersistGate */}
      <Provider store={store}>
        <Layout img={imgSrc}>
          <Component {...pageProps} />
        </Layout>
      </Provider>
    </>
  );
}

export default appWithTranslation(MyApp);
