import Image from "next/image";
import avatar from "../../assets/images/avatar/avatar.png";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectuserProfile } from "../../Redux/user/UserSlice";
import { useMediaQuery } from "react-responsive";

function UserInfo({ info }) {
  const isMobile = useMediaQuery({ maxWidth: 767 }); // For mobile

  const userProfile = useSelector(selectuserProfile);
  // const fullname = localStorage.getItem("fullname");
  // const email = localStorage.getItem("email");
  return (
    <div className=" ">
      {isMobile ? (
        <div className="d-flex ">
          <Image
            src={avatar}
            className="rounded-circle"
            alt="avatar"
            width="120"
            height="120"
          />
          <div className="d-flex mt-3 flex-column ms-2">
            <span className="profile-details-title">
              {userProfile
                ? userProfile?.firstname + " " + userProfile?.lastname
                : "fullname"}
            </span>
            <span className="profile-details-sub-title">
              {userProfile ? userProfile?.email : "email"}
            </span>

            <span className="profile-details-sub-title">
              {userProfile && userProfile?.phoneNumber}
            </span>
            <span className="text-gray">
              {userProfile && userProfile?.country}
            </span>
          </div>
        </div>
      ) : (
        <div className="d-flex ">
          <Image
            src={avatar}
            className="rounded-circle"
            alt="avatar"
            width="60"
            height="60"
          />
          <div className="d-flex mt-3 flex-column ms-2">
            <h2 className="text-primary fs-6 mb-0">
              {/* {userProfile
                ? userProfile?.firstname + " " + userProfile?.lastname
                : fullname} */}
               { userProfile?.firstname + " " + userProfile?.lastname}
            </h2>
            <span className="text-gray">
              {/* {userProfile ? userProfile?.email : email} */}
              { userProfile?.email}
            </span>
 
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(UserInfo);
