import Image from "next/image";
import React from "react";
import { useTranslation } from "react-i18next";

import newsLetterImg from "../../assets/images/landingpage/newsletter.svg";

const NewsLetter = () => {
  const { t } = useTranslation();

  return (
    <div className="newsletter-container">
      <div className="newsletter-sub">
        <div className="newsletter-img-desc">
          <div className="w-100 ">
            <Image alt="newsletter" src={newsLetterImg} />
          </div>
          <div className="d-flex flex-column ml-4">
            <span className="newsletter-header">{t('newsletter.header')}</span>
            <span className="newsletter-title">{t('newsletter.title')}</span>
            <p className="newsletter-desc">{t('newsletter.description')}</p>
            <span className="newsletter-subscribe">{t('newsletter.subscribe')}</span>
          </div>
        </div>
        <div className="d-flex align-items-stretch">
          <input
            type="text"
            name="newsletter"
            id="newsletter"
            value=""
            //onChange={handleChange}
            placeholder={t('newsletter.placeholder')}
            className="form-input"
          />
          <button className="btn newsletter-button">{t('newsletter.button')}</button>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
