import Image from "next/image";
import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardText,
  CardTitle,
} from "reactstrap";

const CardComponent = ({
  imageUrl,
  title,
  subtitle,
  description,
  iconImg,
  date,
}) => {
  return (
    <>
      <Card
        className="card-blog"
      >
        <div className="card-image-container">
        <Image alt="Sample" src={imageUrl} className="card-image" layout="fill" 
            objectFit="cover" />
        </div>
        
        <CardBody className="card-blog-body">
          <CardTitle className="card-blog-title">{title}</CardTitle>
          <CardSubtitle className="mb-2 text-muted">
            {subtitle}
          </CardSubtitle>
          <CardText className="card-text">{description}</CardText>
        </CardBody>
        <CardFooter>
          <div className="text-purple justify-content-between footer-blog-card">
            <span className="px-2 card-date">{date}</span>
            <div className="bookmark-container">
              <Image src={iconImg} alt="bookmark icon" width={25} height={25} />
            </div>
          </div>
        </CardFooter>
      </Card>
    </>
  );
};

export default CardComponent;
