import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Sectiontwo = ({ title, images, description }) => {
  const [isMobile, setIsMobile] = useState(false);
  const titleRef = useRef(null);
  const labelsRef = useRef([]);

  useEffect(() => {
    const currentTitleRef = currentTitleRef;
    const currentLabelsRef = [...labelsRef.current];
    // Vérifier la largeur de l'écran après le montage du composant
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (currentTitleRef) {
      observer.observe(currentTitleRef);
    }
    currentLabelsRef.forEach(label => {
      if (label) {
        observer.observe(label);
      }
    });
    // Définir l'état initial
    handleResize();

    // Écouter les changements de taille de l'écran
    window.addEventListener("resize", handleResize);

    // Nettoyer l'écouteur d'événement
    return () => {
      if (currentTitleRef) {
        observer.unobserve(currentTitleRef);
      }
      currentLabelsRef.forEach(label => {
        if (label) {
          observer.unobserve(label);
        }
      });
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="three-image-section">
      <h2 ref={titleRef} className="section-title section-title-animated">{title}</h2>
      <p className="three-image-section-description-style">{description}</p>
      <div className="image-container">
        {isMobile ? (
          <Swiper spaceBetween={10} slidesPerView={1.2}>
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="image-wrapper">
                  <Image src={image.url} className="w-100" alt={image.alt} />
                  <p ref={el => labelsRef.current[index] = el} className="image-label"> {image.label} </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          images.map((image, index) => (
            <div key={index} className="image-wrapper">
              <Image src={image.url} className="w-100" alt={image.alt} />
              <p ref={el => labelsRef.current[index] = el} className="image-label"> {image.label} </p>           
               </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Sectiontwo;
