import React, { useEffect, useRef, useState, forwardRef } from "react";
import Link from "next/link";
import Image from "next/image";
import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import LogoWhite1 from "../../assets/images/logos/pixeloopLogo.svg";
import OpenNavIcon from "../../assets/images/icons/openNavIocn.png";
import CloseNavIcon from "../../assets/images/icons/CloseNavIocn.png";
import trynow from "../../assets/images/icons/trynow.png";
import { useDispatch, useSelector } from "react-redux";
import {
  selectisauth,
  logout,
  selectuserProfile,
} from "../../Redux/user/UserSlice";
import UserInfo from "../../components/userInfo/userInfo";
import SmokeEffect from "../../components/smokeEffect";
import { hideAlert, showAlert } from "../../Redux/alert/BannerSlice";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

const Header = ({ showMobmenu }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [avatarDropdownOpen, setAvatarDropdownOpen] = React.useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const userProfile = useSelector(selectuserProfile);
  const router = useRouter();
  const isauth = useSelector(selectisauth);
  const { redirect } = router.query;
  const { t } = useTranslation();

  useEffect(() => {
    // if (router.pathname.includes('background') && !isauth) {
    //   dispatch(showAlert({
    //     message: "Please login to try our services",
    //     color: "warning",
    //   }));
    //   router.push(`/login?redirect=${router.pathname}`);
    // }
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0 } // Adjust to trigger based on visibility, you can use a different threshold based on needs
    );

    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        observer.unobserve(headerRef.current);
      }
    };
  }, [router, isauth]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleNavItemClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const handleLogout = () => {
    dispatch(logout());
    dispatch(hideAlert());
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleAvatarDropdown = () => {
    setAvatarDropdownOpen(!avatarDropdownOpen);
  };
  const headerRef = useRef(null);
  const subscriptionTypeName = userProfile?.subscription_type_name;
  const actionsNumber = userProfile?.subscription?.actions;
  const totalActions = userProfile?.actions;
  useEffect(() => {
    if (userProfile) {
      const remainingImages = userProfile?.subscription?.actions;
      const subscriptionExpirationDate = userProfile?.end_date;
      const currentDate = new Date();
      if (remainingImages <= 10 && subscriptionExpirationDate < currentDate) {
        if (remainingImages === 0) {
          dispatch(
            showAlert({
              message:
                " You have 0 remaining images. Please purchase a new subscription.",
              color: "warning",
              autoDismiss: false,
            })
          );
        } else {
          dispatch(
            showAlert({
              message:
                "Your remaining images are less than 10 of the total images.",
              color: "warning",
              autoDismiss: false,
            })
          );
        }
      }

      if (subscriptionExpirationDate < currentDate) {
        const expirationDate = new Date(subscriptionExpirationDate);
        const differenceInDays =
          (expirationDate - currentDate) / (1000 * 3600 * 24);

        if (differenceInDays <= 10) {
          dispatch(
            showAlert({
              message: "Your subscription is expiring in less than 10 days.",
              color: "warning",
              autoDismiss: false,
            })
          );
        }
        if (remainingImages === 0) {
          dispatch(
            showAlert({
              message:
                "You have 0 remaining images. Please purchase a new subscription.",
              color: "warning",
              autoDismiss: false,
            })
          );
        }
      }
    }
  }, [userProfile, dispatch]);

  const handleLanguageChange = (locale) => {
    router.push(router.pathname, router.asPath, { locale });
  };

  const getCurrentLanguageName = () => {
    return router.locale === 'fr' 
      ? 'Français' 
      : 'English';
  };

  return (
    <span
      ref={headerRef}
      className={`navbar-animated header-nav ${
        isOpen ? "navbar-open" : "navbar-closed"
      }`}
    >
      {/* <ShowMgsInfo autoHide={false}  /> */}
      <Navbar
        color="transparent"
        dark
        expand="md"
        className="align-items-center navbar header-content"
      >
        <SmokeEffect></SmokeEffect>
        <Link href="/">
          <div className="d-flex align-items-center cursor-pointer">
            {isauth && isOpen ? (
              <UserInfo info={userProfile} />
            ) : (
              <Image
                src={LogoWhite1}
                // width={140}
                // height={55}
                // sizes="(max-width: 768px) 100vw, 33vw"
                alt="logo"
                onClick={handleNavItemClick}
              />
            )}
          </div>
        </Link>
        <div className="ms-auto d-md-none">
          <Button size="sm" onClick={Handletoggle} className="btndotsheader">
            {isOpen ? (
              // <i className="bi bi-x"></i>
              <Image src={CloseNavIcon} alt="close-nav-icon" />
            ) : (
              <Image src={OpenNavIcon} alt="open-nav-icon" />
            )}
          </Button>
        </div>
        <Collapse
          isOpen={isOpen}
          navbar
          className="d-lg-flex justify-content-lg-start justify-content-xl-center"
        >
          {isauth && isOpen ? (
            <div className="header-input">
              <div className="input-header">
                <span className="start">{subscriptionTypeName} </span>
                <span className="end">
                  {actionsNumber}/{totalActions} Credits
                </span>
              </div>
              <div>
                <a
                  className="nav-link  my-2 btn"
                  href=""
                  onClick={handleNavItemClick}
                >
                  {" "}
                  Upgrade Plan
                </a>
              </div>
            </div>
          ) : (
            <span></span>
          )}
          <Nav
            className={`d-flex justify-content-around ${
              isauth && isOpen ? "navbar-mobille" : ""
            }`}
            navbar
          >
           <NavItem>
  <Link href="/">
    <a className="nav-link navbarItem" onClick={handleNavItemClick}>
      {t('header.home')}
    </a>
  </Link>
</NavItem>

            <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle nav caret className="navbarItem">
                <span className="try-style">{t('header.tryNow')}</span>
                {isOpen ? (
                  <span style={{ paddingLeft: "80%" }}>
                    <Image src={trynow} alt="try icon" />
                  </span>
                ) : (
                  <span style={{ paddingLeft: "2%" }}>
                    {" "}
                    <Image src={trynow} alt="try icon" />{" "}
                  </span>
                )}
              </DropdownToggle>
              <DropdownMenu className="use-case-drop-dowbmenu">
                <Link href="/remove-background">
                  <DropdownItem className="custom-dropdown-item">
                    <span
                      className="nav-link me-5 navbarItem navbar-item"
                      onClick={handleNavItemClick}
                    >
                      <span
                        className="icon-wrapper1"
                        style={{ marginRight: "0.5rem" }}
                      ></span>
                      {t('header.removeBackground')}
                    </span>
                  </DropdownItem>
                </Link>
                <Link href="/add-background">
                  <DropdownItem className="custom-dropdown-item">
                    <a
                      className="nav-link navbarItem"
                      href=""
                      onClick={handleNavItemClick}
                    >
                      <span
                        className="icon-wrapper"
                        style={{ marginRight: "0.5rem" }}
                      ></span>
                      {t('header.addBackground')}
                    </a>
                  </DropdownItem>
                </Link>
                <Link href="/resize-image">
                  <DropdownItem className="custom-dropdown-item">
                    <a
                      className="nav-link navbarItem"
                      href=""
                      onClick={handleNavItemClick}
                    >
                      <span
                        className="icon-wrapper5"
                        style={{ marginRight: "0.5rem" }}
                      ></span>
                      {t('header.resizeImage')}
                    </a>
                  </DropdownItem>
                </Link>
                <Link href="/converter-image">
                  <DropdownItem className="custom-dropdown-item">
                    <a
                      className="nav-link navbarItem"
                      href=""
                      onClick={handleNavItemClick}
                    >
                      <span
                        className="icon-wrapper6"
                        style={{ marginRight: "0.5rem" }}
                      ></span>
                      {t('header.convertImage')}
                    </a>
                  </DropdownItem>
                </Link>
            
              </DropdownMenu>
            </Dropdown>
            <Dropdown nav isOpen={languageDropdownOpen} toggle={() => setLanguageDropdownOpen(!languageDropdownOpen)} className="d-md-none">
              <DropdownToggle nav caret className="navbarItem">
                <span className="try-style">{t('header.language')}</span>
                {isOpen ? (
                  <span style={{ paddingLeft: "75%" }}>
                    <Image src={trynow} alt={t("imagealt.languageIcon")}/>
                  </span>
                ) : (
                  <span>
                    <Image src={trynow}alt={t("imagealt.languageIcon")}  />
                  </span>
                )}
              </DropdownToggle>
              <DropdownMenu className="use-case-drop-dowbmenu">
                <DropdownItem className="custom-dropdown-item" onClick={() => handleLanguageChange('en')}>
                  <span className="nav-link navbarItem" onClick={handleNavItemClick}>
                    {t('header.chooselanguage.english')}
                  </span>
                </DropdownItem>
                <DropdownItem className="custom-dropdown-item" onClick={() => handleLanguageChange('fr')}>
                  <span className="nav-link navbarItem" onClick={handleNavItemClick}>
                    {t('header.chooselanguage.french')}
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <NavItem>
              <Link href="/tarifs">
                <a
                  className="nav-link navbarItem"
                  href=""
                  onClick={handleNavItemClick}
                >
                  {t('header.pricing')}
                </a>
              </Link>
            </NavItem>
            <NavItem>
              <Link href="/">
                <a
                  className="nav-link navbarItem hide-on-tablet"
                  href=""
                  onClick={handleNavItemClick}
                >
                  {t('header.toolsApi')}
                </a>
              </Link>
            </NavItem>
            <NavItem>
              <Link href="/blog">
                <a
                  className="nav-link navbarItem"
                  href=""
                  onClick={handleNavItemClick}
                >
                  {t('header.blog')}
                </a>
              </Link>
            </NavItem>
            <NavItem>
              <Link href="/contact-us">
                <a
                  className="nav-link navbarItem hide-on-tablet"
                  href=""
                  onClick={handleNavItemClick}
                >
                  {t('header.contactUs')}
                </a>
              </Link>
            </NavItem>
            {!isauth && (
              <div className="d-md-none">
                <NavItem>
                  <Link href="/login">
                    <a
                      className="nav-link navbarItem "
                      href=""
                      onClick={handleNavItemClick}
                    >
                      {t('header.signin')}
                    </a>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link href="/register">
                    <a
                      className="nav-link  btn"
                      href=""
                      onClick={handleNavItemClick}
                      style={{ marginTop: "3.5rem" }}
                    >
                      {t('header.signup')}
                    </a>
                  </Link>
                </NavItem>
              </div>
            )}

            {isauth && (
              <div className="d-md-none">
                <Link href="/profile">
                  <NavItem>
                    <a
                      className="nav-link navbarItem"
                      href=""
                      onClick={handleNavItemClick}
                    >
                      {t('header.profile.accountDetails')}
                    </a>
                  </NavItem>
                </Link>
                <Link href="/login">
                  <NavItem onClick={handleLogout}>
                    <a
                      className="nav-link   btn"
                      href=""
                      onClick={handleNavItemClick}
                      style={{ marginTop: "1.5rem", marginInline: "4%" }}
                    >
                      {" "}
                      {t('header.profile.logout')}
                    </a>
                  </NavItem>
                </Link>
              </div>
            )}
          </Nav>
        </Collapse>
        <span className=" navbar-left-side">
          {!isauth && (
            <span className="d-none d-md-flex   justify-content-center w-100">
              <Link href="/login">
                <Button
                  className="btnheader btnconnect me-2 mt-1 btn"
                  color="transparent"
                >
                  {t('header.signin')}
                </Button>
              </Link>
              <Link href="/register">
                <Button
                  className="btn me-4 mt-1 btn "
                  onClick={handleNavItemClick}
                >
                  {t('header.signup')}
                </Button>
              </Link>
            </span>
          )}
          {isauth && (
            <span className="d-none d-md-flex justify-content-center w-100">
              <Dropdown
                nav
                isOpen={avatarDropdownOpen}
                toggle={toggleAvatarDropdown}
                className="ms-auto "
              >
                <DropdownToggle nav caret className="  align-items-center">
                  <UserInfo info={userProfile} />
                </DropdownToggle>
                <DropdownMenu className="setting-drop-dowbmenu dropdown-profile">
                  {/* <Link href=""> */}
                  {/* <DropdownItem> */}
                  <div className="header-input-dropdown-profile">
                    <div className="input-header">
                      <span className="start">{subscriptionTypeName} </span>
                      <span className="end">
                        {actionsNumber !== undefined
                          ? `${actionsNumber} ${t('header.profile.credits')}`
                          : `${totalActions} ${t('header.profile.credits')}`}
                      </span>
                    </div>
                  </div>
                  {/* </DropdownItem> */}
                  {/* </Link> */}
                  <Link href="">
                    <DropdownItem>
                      <a
                        className="nav-link  my-2 btn btn-upgrade"
                        href=""
                        onClick={handleNavItemClick}
                      >
                        <span className="icon-wrapper4"></span>
                        {t('header.profile.upgradePlan')}
                      </a>
                    </DropdownItem>
                  </Link>
                  <Link href="/profile">
                    <DropdownItem>
                      <span
                        className="profile-Label"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={handleNavItemClick}
                      >
                        {t('header.profile.accountDetails')}
                      </span>
                    </DropdownItem>
                  </Link>
                  <Link href="/profile">
                    <DropdownItem>
                      <span
                        className="profile-Label"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={handleNavItemClick}
                      >
                        {t('header.profile.subscriptionDetails')}
                      </span>
                    </DropdownItem>
                  </Link>
                  <Link href="/profile">
                    <DropdownItem>
                      <span
                        className="profile-Label"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={handleNavItemClick}
                      >
                        {t('header.profile.changePassword')}
                      </span>
                    </DropdownItem>
                  </Link>
                  <Link href="/profile">
                    <DropdownItem>
                      <span
                        className="profile-Label"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={handleNavItemClick}
                      >
                        {t('header.profile.subscriptionHistory')}
                      </span>
                    </DropdownItem>
                  </Link>

                  <UncontrolledDropdown className="mx-2 d-none d-md-block" direction="start">
                    <DropdownToggle caret className="language-btn py-2">
                      {getCurrentLanguageName()}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleLanguageChange("en")}>
                        English
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={() => handleLanguageChange("fr")}>
                        Français
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <DropdownItem>
                    <span
                      className="nav-link btn btn-logout"
                      onClick={handleLogout}
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      {t('header.profile.logout')}
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </span>
          )}
        </span>
      </Navbar>
    </span>
  );
};

export default Header;
