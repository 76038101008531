import PriceCard from "../PriceCard";
import Freeicon from "../../assets/images/icons/freeaccountIcon.png";
import mastergoldicon from "../../assets/images/icons/premiumacoountIcon.png";
import Premiumicon from "../../assets/images/icons/primaryicon.png";
import Goldicon from "../../assets/images/icons/goldicon.png";
import ShowMgsInfo from "../showMsgInfo";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useTranslation } from "next-i18next";

export const accountType = {
  free: Freeicon,
  entreprise: mastergoldicon,
  premium: Premiumicon,
  Gold: Goldicon,
};

const PricingSection = ({ title, description, pricingPlans }) => {
  const { t } = useTranslation();
  const plan = [
    {
      id: "66c5b100ee20054aadb9f4e0",
      name: "Remove Background",
    },
    {
      id: "66c5b15aee20054aadb9f4e1",
      name: "Add Background",
    },
  ];

  return (
    <div className="pricing-section">
      <div className="pricesHeader">
        <h2 className="pricingTitle">{title}</h2>
        <p className="pricingDesc">{description}</p>
      </div>
      <ShowMgsInfo />

      <div className="pricing-cards swiper-container">
        <Swiper
          spaceBetween={10}
          slidesPerView={1.2}
          pagination={{ clickable: true }}
          className="pricing-swiper"
        >
          {pricingPlans?.map((plan, index) => (
            <SwiperSlide key={index}>
              <PriceCard
                key={index}
                accountType={plan.name}
                price={plan.price}
                features={plan.actions}
                buttonLabel={t("pricing.buttons.buyNow")}
                icon={accountType[plan.code]}
                quantity={plan.quantity}
                id={plan._id}
              />
            </SwiperSlide>
          ))}
          <SwiperSlide>
            <PriceCard
              accountType="Entreprise"
              price=""
              features={plan}
              buttonLabel={t("pricing.buttons.contactUs")}
              icon={mastergoldicon}
              quantity="+10000"
              id="{plan._id}"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="pricing-cards desktop-view">
        {pricingPlans?.map((plan, index) => (
          <PriceCard
            key={index}
            accountType={plan.name}
            price={plan.price}
            features={plan.actions}
            buttonLabel={t("pricing.buttons.buyNow")}
            icon={accountType[plan.code]}
            quantity={plan.quantity}
            id={plan._id}
          />
        ))}
        <PriceCard
          accountType="Entreprise"
          price=""
          features={plan}
          buttonLabel={t("pricing.buttons.contactUs")}
          icon={mastergoldicon}
          quantity="+10000"
          id="{plan._id}"
        />
      </div>
    </div>
  );
};

export default PricingSection;
