import { useTranslation } from 'next-i18next';
import Head from 'next/head';

const SEO = () => {
  const { t } = useTranslation('common');

  return (
    <Head>
      <title>{t('seo.title')}</title>
      <meta name="description" content={t('seo.description')} />
      <meta name="keywords" content={t('seo.keywords')} />
    </Head>
  );
};

export default SEO; 